export const inputsArray = [
  'nombre',
  'apellido',
  'calle',
  'numero',
  'complemento',
  'localidad',
  'CP',
  'telefono',
  'email',
  'contraseña',
  'confirme la contraseña',
];
